export const metrics = {
  supplier_team: {
    name: 'shared.metric.supplier_team.name',
    unit: 'suppliers'
  },
  string_library_keys: {
    name: 'shared.metric.string_library_keys.name',
    unit: 'keys'
  },
  automation_workflows: { name: '', unit: 'automation workflows' },
  supplier_translated_words: {
    name: 'shared.metric.supplier_translated_words.name',
    unit: 'words'
  },
  caas_generated_words: {
    name: 'shared.metric.caas_generated_words.name',
    unit: 'words'
  },
  custom_machine_translation: {
    name: 'shared.metric.custom_machine_translation.name',
    unit: 'words'
  }
}
