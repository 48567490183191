<template>
  <BaseGrid>
    <BaseColumn>
      <div
        class="flex flex-col justify-center items-center h-full p-4 mobilePortrait:p-12 mobileLandscape:py-8 mobileLandscape:px-32 tabletPortrait:p-8 desktopLarge:py-8 desktopLarge:px-16"
      >
        <form
          class="w-full flex flex-col justify-center items-center"
          @submit.prevent="authenticateUser"
        >
          <LogoHeader :title="$t('shared.label.welcome')" />
          <div>
            <SwitchButton
              data-cy="sandbox_switch"
              v-model="sandboxEnv"
              class="mt-4 [&>span]:!text-base [&>span]:!text-neutral-200"
              :label="$t('shared.label.sandbox')"
            />
          </div>
          <Label
            :title="$t('shared.label.email_address')"
            class="w-full max-w-sm mt-6 desktopExtraLarge:max-w-md"
          >
            <InputFieldEmail
              id="platform__username__field"
              with-icon
              :placeholder="$t('shared.label.enter_email')"
              @inputChange="handleEmail"
            />
          </Label>
          <Label
            :title="$t('shared.label.password')"
            class="w-full max-w-sm mt-6 desktopExtraLarge:max-w-md"
          >
            <InputFieldSecret
              id="platform__password__field"
              :placeholder="$t('shared.label.enter_password')"
              autocomplete="current-password"
              @change="handlePassword"
            />
          </Label>
          <div class="w-full max-w-sm mt-6 desktopExtraLarge:max-w-md mt-6">
            <BtnPrimary
              id="platform__login__button"
              class="w-full"
              type="submit"
              :title="$t('shared.button.login')"
            />
          </div>
          <BtnGoogleSignin
            class="w-full max-w-sm desktopExtraLarge:max-w-md mt-8"
            @success="handleLoginSuccess"
          />
          <div
            class="w-full max-w-sm text-right desktopExtraLarge:max-w-md mt-6"
          >
            <BtnSecondary
              id="platform__forgot__password__button"
              data-cy="forgot_password_button"
              class="px-0"
              type="button"
              :title="$t('shared.button.forgot_password')"
              @click="router.push({ name: 'forgotPassword' })"
            />
          </div>
        </form>
      </div>
    </BaseColumn>
    <BaseColumnInfo>
      <div class="h-full w-full flex justify-center items-center">
        <img
          class="w-full h-auto object-cover"
          alt="EasyTranslate"
          width="623"
          height="578"
          src="@/assets/images/g2_winter_platform.png"
        />
      </div>
    </BaseColumnInfo>
  </BaseGrid>
</template>
<script setup>
import ApiService from '@/api/new/services/api.service'
import { TokenService } from '@/api/new/services/storage.service'
import BaseColumn from '@/components/shared/BaseColumn'
import BaseColumnInfo from '@/components/shared/BaseColumnInfo'
import BaseGrid from '@/components/shared/BaseGrid'
import BtnGoogleSignin from '@/components/shared/btn/BtnGoogleSignin'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import InputFieldEmail from '@/components/shared/input/InputFieldEmail'
import InputFieldSecret from '@/components/shared/input/InputFieldSecret'
import Label from '@/components/shared/label/Label'
import LogoHeader from '@/components/shared/LogoHeader'
import SwitchButton from '@/components/shared/switch/SwitchButton'
import i18n from '@/i18n'
import { $cookies } from '@/main'
import moment from 'moment'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const { locale } = useI18n()
const route = useRoute()
const router = useRouter()

const sandboxEnv = ref(false)
const email = ref('')
const password = ref('')

const currentUser = computed(() => store.state.workspace.currentUser)
const currentUserAccounts = computed(
  () => store.state.workspace.currentUserAccounts
)
const verifiedMfa = computed(() => store.state.workspace.verifiedMfa)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)
const isAdmin = computed(() => store.getters['workspace/isAdmin'])
const isNewUserOfExistingAccount = computed(
  () => store.getters['workspace/isNewUserOfExistingAccount']
)
const userType = computed(() => currentUser.value.type)

onBeforeRouteUpdate((to, from, next) => {
  const teamIdentifier = $cookies.get('team_identifier')
  if (to.name === 'outsiderRedirect' && to.query.deeplink) {
    next({ query: to.query })
    if (teamIdentifier) {
      router.push(
        `/${i18n.i18n.global.locale.value}/customer/${teamIdentifier}/${to.query.deeplink}`
      )
    }
  }
})

onMounted(() => {
  if (route.query.referral === 'g2') {
    ;(function (c, p, d, u, i) {
      u =
        'https://tracking.g2crowd.com/funnels/' +
        c +
        '.js?stage=finish&stype=offer'
      i = document.createElement('script')
      i.async = true
      i.src = u
      d.getElementsByTagName('head')[0].appendChild(i)
    })('34859d2c-7978-4762-8690-fe778a6ebf92', document.location.href, document)
  }
  const env = $cookies.get('env')
  if (env && env === 'sandbox') {
    ApiService.init(process.env.VUE_APP_ROOT_API_SANDBOX)
  } else {
    ApiService.init(process.env.VUE_APP_ROOT_API)
  }
  const teamIdentifier = $cookies.get('team_identifier')
  const refreshToken = $cookies.get(process.env.VUE_APP_REFRESH_TOKEN_COOKIE)
  if (route.name === 'outsiderRedirect' && route.query.deeplink) {
    if (teamIdentifier) {
      router.push(
        `/${i18n.i18n.global.locale.value}/customer/${teamIdentifier}/${route.query.deeplink}`
      )
    }
  } else if (refreshToken) {
    handleLoginSuccess()
  } else {
    email.value = ''
    password.value = ''
    TokenService.removeAll()
  }
  stopLoader()
})

const login = (payload) => store.dispatch('auth/login', payload)
const getCurrentUser = () => store.dispatch('workspace/getCurrentUser')
const startLoader = (payload) => store.dispatch('loading/startLoader', payload)
const stopLoader = () => store.dispatch('loading/stopLoader')
const warning = (payload) => store.dispatch('toast/warning', payload)
const info = (payload) => store.dispatch('toast/info', payload)
const setCurrentAccount = (payload) =>
  store.commit('workspace/setCurrentAccount', payload)
const setMfaStatus = (payload) =>
  store.commit('workspace/setMfaStatus', payload)
const clearErrorQueue = (payload) =>
  store.commit('toast/clearErrorQueue', payload)

function handleEmail({ value }) {
  email.value = value
}
function handlePassword(value) {
  password.value = value
}
async function handleLoginSuccess() {
  await getCurrentUser()
  await setUser()
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    loginStatus: 'logged in',
    userId: currentUser.value?.attributes?.hash
  })
  stopLoader()
}
async function authenticateUser() {
  startLoader()
  const data = {
    username: email.value,
    password: password.value
  }
  let loginSuccess = true
  try {
    await login(data)
  } catch {
    stopLoader()
    loginSuccess = false
  } finally {
    if (loginSuccess) {
      clearErrorQueue()
      await handleLoginSuccess()
    }
  }
}
async function setAdminCookies() {
  $cookies.set('userType', 'admin')
  $cookies.set('firstName', currentUser.value.attributes.first_name)
  $cookies.set('lastName', currentUser.value.attributes.last_name)
  $cookies.set('supplierOnboarding', 'false')
  $cookies.set('superAdmin', 'true')
}
async function setSupplierCookies(onboardingStatus, id) {
  $cookies.set('userType', 'supplier')
  $cookies.set('superAdmin', 'false')
  $cookies.set('supplierOnboarding', onboardingStatus)
  await $cookies.set('supplierId', id)
}
async function setCustomerCookies() {
  $cookies.set('userType', 'customer')
  $cookies.set('firstName', currentUser.value.attributes.first_name)
  $cookies.set('lastName', currentUser.value.attributes.last_name)
  $cookies.set('superAdmin', 'false')
}
async function setUser() {
  const userLanguage = currentUser.value.attributes.platform_language
  const cookiesLanguage = $cookies.get('locale') || 'en'
  if (!userLanguage && !cookiesLanguage) {
    locale.value = 'en'
    moment.locale('en')
  } else if (userLanguage !== cookiesLanguage) {
    $cookies.set('locale', userLanguage)
    locale.value = userLanguage
    moment.locale(userLanguage)
  }
  locale.value =
    currentUser.value.attributes.platform_language ||
    $cookies.get('locale') ||
    'en'
  moment.locale(i18n.i18n.global.locale.value)
  if (isAdmin.value) {
    await setAdminCookies()
    if (route.query.redirect) {
      const account = route.query.redirect.split('/')[3]
      if (
        currentUserAccounts.value.some(
          (userAccount) => userAccount.attributes.team_identifier === account
        )
      ) {
        router.push({
          path: route.query.redirect,
          params: { locale: i18n.i18n.global.locale.value }
        })
        return
      }
    }
    router.push({
      name: 'accountsList',
      params: { locale: i18n.i18n.global.locale.value }
    })
    return
  }
  // Supplier
  if (userType.value == 'supplier') {
    if (currentUser.value.attributes.status != 'accepted') {
      await setSupplierCookies(false, currentUser.value.id)
      if (route.query.redirect) {
        const user = route.query.redirect.split('/')[2]
        if (user === 'supplier') {
          let newRoute = {
            path: route.query.redirect,
            params: { locale: i18n.i18n.global.locale.value }
          }
          if (route.query.team) {
            newRoute = { ...route, query: { team: route.query.team } }
          }
          router.push(newRoute)
        } else {
          router.push({
            name: 'supplierDashboard',
            params: { locale: i18n.i18n.global.locale.value }
          })
        }
      } else {
        router.push({
          name: 'supplierDashboard',
          params: { locale: i18n.i18n.global.locale.value }
        })
      }
      return
    }
    await setSupplierCookies(true, currentUser.value.id)
    window.location.href = `${window.location.protocol}//${process.env.VUE_APP_COOKIE_DOMAIN}/${i18n.i18n.global.locale.value}/supplier/onboarding/${currentUser.value.id}/wizard/about-you`
  }
  // Customer
  if (userType.value == 'customer') {
    if (isNewUserOfExistingAccount.value) {
      info({
        message: $t('customer.account_info.existing_domain')
      })
      stopLoader()
      return
    }
    await setCustomerCookies(true)
    if ($cookies.get('onboarding_progress') === 'true') {
      const isSameUser =
        $cookies.get('onboarding_name') ===
        `${currentUser.value.attributes.first_name} ${currentUser.value.attributes.last_name}`
      if (isSameUser) {
        router.push({ name: 'onboardingSignup' })
      }
      return
    }
    if (currentUserAccounts.value.length > 1) {
      router.push({
        name: 'accountsList',
        params: { locale: i18n.i18n.global.locale.value },
        query: { ...route.query }
      })
      return
    } else if (currentUserAccounts.value.length === 1) {
      setCurrentAccount(currentUserAccounts.value[0].attributes)
      if (route.query.redirect) {
        const account = route.query.redirect.split('/')[3]
        if (currentAccountId.value === account) {
          const { redirect, ...restQuery } = route.query
          router.push({
            path: route.query.redirect,
            query: restQuery
          })
          return
        } else if (route.query.deeplink) {
          router.push(
            `/${i18n.i18n.global.locale.value}/customer/${currentAccountId.value}/${route.query.deeplink}`
          )
        } else {
          router.push({
            name: 'clientOverview',
            params: {
              locale: i18n.i18n.global.locale.value,
              teamIdentifier: currentAccountId.value
            }
          })
        }
      } else {
        router.push({
          name: 'clientOverview',
          params: {
            locale: i18n.i18n.global.locale.value,
            teamIdentifier: currentAccountId.value
          }
        })
      }
    } else {
      warning({
        message: $t('customer.toast.warning.existing_team_signup')
      })
    }
  }
}

watch(
  () => sandboxEnv.value,
  (sandbox) => {
    if (sandbox) {
      $cookies.set('env', 'sandbox')
      ApiService.init(process.env.VUE_APP_ROOT_API_SANDBOX)
    } else {
      $cookies.set('env', process.env.VUE_APP_ENV)
      ApiService.init(process.env.VUE_APP_ROOT_API)
    }
  }
)

watch(
  () => verifiedMfa.value,
  (value) => {
    if (value) handleLoginSuccess()
  }
)

onUnmounted(() => {
  setMfaStatus(false)
})
</script>
