<template>
  <div class="pb-[90px]">
    <Header>
      <HeaderDesktop />
    </Header>
    <Section>
      <template #title>
        <SectionTitle :name="$t('customer.overview.folders.title')" />
      </template>
      <template v-if="allFolders.length > 0" #action>
        <BtnIconLink
          :title="$t('shared.button.view_all')"
          icon="bars"
          to="clientFolders"
        />
      </template>
    </Section>
    <FoldersGrid v-if="allFolders.length > 0">
      <template v-if="showListView">
        <FolderRow
          v-for="(folder, i) in limitFolderList(4)"
          :key="folder.id"
          :data="folder"
          :whiteBg="i % 2 === 0"
        />
      </template>
      <template v-else>
        <FolderCard
          v-for="folder in limitFolderList(4)"
          :key="folder.id"
          :data="folder"
        />
      </template>
    </FoldersGrid>
    <NoResultFoundAnimation
      v-if="allFolders.length === 0 && allProjects.length === 0"
      :text="
        route.query.new == 'true'
          ? $t('customer.overview.projects.animation.title.new')
          : $t('customer.overview.projects.animation.title')
      "
    />
    <NoResultFound
      v-if="allFolders.length === 0 && allProjects.length > 0"
      :title="$t('folders.empty.title')"
      :text="$t('folders.empty.text')"
    />
    <Section :multiple-actions="true">
      <template #title>
        <SectionTitle :name="$t('customer.overview.projects.title')" />
      </template>
      <template #action>
        <BtnIconLink
          :title="$t('shared.button.view_all')"
          icon="bars"
          to="clientProjects"
        />
      </template>
    </Section>
    <ProjectFilters />
    <ProjectsGrid>
      <template v-if="showListView">
        <ProjectRow
          v-for="(project, i) in projects"
          :key="project.id"
          :data="project"
          :whiteBg="i % 2 === 0"
        />
      </template>
      <template v-else>
        <ProjectCard
          v-for="project in projects"
          :key="project.id"
          :data="project"
        />
      </template>
    </ProjectsGrid>
    <NoResultFound
      v-if="allProjects.length === 0"
      :title="$t('projects.empty.title')"
      :text="$t('projects.empty.text')"
    />
  </div>
</template>
<script setup>
import Header from '@/components/header/Header'
import HeaderDesktop from '@/components/header/HeaderDesktop'
import Section from '@/components/section/Section'
import SectionTitle from '@/components/section/SectionTitle'
import BtnIconLink from '@/components/shared/BtnIconLink'
import FolderCard from '@/components/shared/folder/FolderCard'
import FolderRow from '@/components/shared/folder/FolderRow'
import FoldersGrid from '@/components/shared/folder/FoldersGrid'
import NoResultFound from '@/components/shared/NoResultFound'
import NoResultFoundAnimation from '@/components/shared/NoResultFoundAnimation'
import ProjectCard from '@/components/shared/project/ProjectCard'
import ProjectFilters from '@/components/shared/project/ProjectFilters'
import ProjectRow from '@/components/shared/project/ProjectRow'
import ProjectsGrid from '@/components/shared/project/ProjectsGrid'
import { computed, onMounted, onUpdated, watch } from 'vue'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()

onBeforeRouteLeave((to, from) => {
  if (to.name == 'freemiumEasyOrderFlow') {
    return false
  }
})

const stopLoader = () => store.dispatch('loading/stopLoader')
const toggleAreaOfWorkModal = () => store.commit('modals/toggleAreaOfWorkModal')
const getProjects = (payload) =>
  store.dispatch('workspace/getProjects', payload)

const showListView = computed(() => store.state.account.showListView)
const limitFolderList = computed(() => store.getters['folder/limitFolderList'])

const allProjects = computed(() => store.state.workspace.allProjects)
const allFolders = computed(() => store.state.folder.allFolders)
const isFreemiumScaleupStartup = computed(
  () => store.getters['account/isFreemiumScaleupStartup']
)
const currentUser = computed(() => store.state.workspace.currentUser)

const projects = computed(() => {
  if (allProjects.value) {
    return allProjects.value.filter((project) => {
      return project.attributes.folder === null
    })
  } else {
    return []
  }
})

watch(
  () => projects.value,
  () => {
    stopLoader()
  },
  { immediate: true }
)

onUpdated(() => {
  stopLoader()
})

onMounted(async () => {
  if (
    !currentUser?.value?.attributes?.onboarding?.area_of_work &&
    isFreemiumScaleupStartup.value
  ) {
    toggleAreaOfWorkModal()
  }
  if (allProjects.value.length > 0 && projects.value.length === 0) {
    await getProjects({ search: null, currentPage: 1 })
  }
})
</script>
